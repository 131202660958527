@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&family=DynaPuff&family=Roboto+Mono:wght@200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
:root{
   --bgcolor: linear-gradient(20deg,rgb(8, 3, 90),rgb(14, 54, 13),rgb(38, 21, 54));
   --bgcolor2: linear-gradient(20deg,rgba(27, 62, 0, 0.225),rgb(41, 2, 57),rgb(41, 2, 57));
   --fontFamily: 'Anton', sans-serif
}
#root{
   position: relative;
}
.fontStyle{
    font-family: 'Roboto';
   
}
.btncolor{
    background: var(--bgcolor) !important;
    color: white;
}
.textcolor{
   color:  var(--bgcolor);
   padding: 1px;
}
.main-con{
  
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}
.inner-main-con{
   max-width: 1440px;
}
/* General width view */
.general-width-veiw{
   max-width: 1440px;
   margin-bottom: 4em;

}
.Price-text{
    position: absolute;
    top: 7em;
    left: 0;
    color: white;
    background-color: rgb(92, 12, 12);
    padding: 5px;
    z-index: 3;
    font-weight: 700;
    
 }
 
 .status-text{
   position: absolute;
   top: 7em;
   right: 0;
   color: white;
   background-color: rgb(92, 12, 12);
   padding: 5px;
   z-index: 3;
   font-weight: 700;
 }
 

 .Hover-elemnt{
    padding: 10px;
    height: auto;
    background: linear-gradient(30deg,rgb(54, 1, 61),
    rgb(0, 10, 53));
 }

 .appBar-preview{
  background: linear-gradient(30deg,rgb(54, 1, 61),
  rgb(0, 10, 53));
  
 }
 .main-preview-con{
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100vh;
   overflow-y: auto;
  
  
 }
 .PreviewCon{
    display: flex;
    width: 100%;
    height: 100%;
    color: rgb(0, 10, 53);
   
 } 
.ProductPreview{
    width: 50%;
    height: 100%;
    overflow-y: auto;
    padding: 0 18px;
    color: rgb(0, 10, 53);
  
}
.InstructorPreview{
  
   width: 50%;
   height: 100%;
   padding: 0 18px;
   display: flex;
   flex-direction: row-reverse;
   padding-top: 4em;

}
.InstructorPreviewViewText-con{
   white-space: nowrap;
}
.ProductViewText-CourseDescreption-con{
    margin-bottom: 9em;
}
.img-InstructorPreview-con{
  
   width: 100%;
   height: auto;
   padding: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
}
.img-InstructorPreview-con img{
    width: 150px;
    border-radius: 7px;
    box-shadow: 1px 3px 10px rgb(0, 10, 53);
}

.loadingCon{
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 0.411);
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.alertbox-con{
   position: fixed;
   z-index: 9999;
   width: 30%;
   height: 20%;
   bottom: 0;
   right: 0;
   background-color: rgba(0, 0, 0, 0);
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 1em;
   
}



.MainContainer{
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    
}
.TextViewContainer{
    height: 100%;
    width: 40%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
   
}
.inner-TextViewHeader{
     width: 100%;
     height: 150px;
     display: flex;
     flex-direction: row;
}
.inner-TextViewHeader-inner-img{
    
    width: 30%;
    height: 100%;
}
.inner-TextViewHeader-inner-text{    
    width: 70%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

}

.inner-TextViewHeader-inner-text div{
      height: 100%;
      width: 20%;
      display: flex;
      align-items: center;
      justify-content: center;
}


.VidoeContainer{
  
   height: 100%;
   width: 60%;
   display: flex;
   justify-content: center;
   align-items: flex-start;
  
  

}

.videoCon{
   width: 80%;
   height: auto;
   background-color: white;
   margin-top: 90px;
   display: flex;
   justify-content: center;
   align-items: center;

}


.H2TogglBtn{
   background-color: red;
}

/* MMT Training Cneter */

.mmt-center-con{
   display: grid;
   grid-template-columns: repeat(12,1fr);
   grid-template-rows: 400px auto;  
   
 }
 .mtc-header{
   background-color: white;
   position: fixed;
   height: auto;
   width: 100%;
   z-index: 999;
   box-shadow: 2px 3px 10px rgb(0, 0, 0);
 }
 .logo{
    width: 200px;
 }
 .welcome-text-con{
   height: 10em;
   width: 50%;
   white-space: nowrap;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 .mtc-inner-nav-con{
    justify-content: center;
    align-items: center;
    flex-direction: row !important;
  
 }
 .mtc-section-1{
   grid-column: 1/13;
   margin-top: 6em;
   display: flex;
   justify-content: center;
   align-items: center;
 }

 
 .mtc-section{
   background-image: url(../public/assets/imgs/svg-live-2.png);
   background-repeat: no-repeat;
   background-size: cover; 
   grid-column: 1/13;
   display: grid;
   grid-gap: 2em;
   grid-template-columns: repeat(12,1fr);
   grid-template-rows: auto auto 600px auto auto;
   
 }
 .mtc-sec-tabs-card-con{
   /* background: linear-gradient(20deg,rgb(8, 3, 90),rgb(14, 54, 13),rgb(38, 21, 54)); */
   grid-column: 1/13; 
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding-bottom: 4rem;
   padding-left: 4rem;
   padding-right: 4rem;
}
.tabs-table-con{
    width: 90%;
    box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.438);
}
.mtc-sec-info-pic-con{
   
    grid-column: 1/13;
    background: var(--bgcolor);
}

.inner-info-pic-con{

   width: 100%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   padding-left: 4rem;
   padding-right: 4rem;

}
.sec-pic-card-con{
    width: 90%;
    height: 90%;
    display: flex;
}
.sec-info-pic-con{
   
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.box--{
   
    height: 25em;
    width: 26em;
    border-top-left-radius: 30%;
    border-bottom-right-radius: 30%;
    position: absolute;
}
.pic-box-1{
    z-index: 2;
    transform: skew(0deg,5deg);
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.47);
    background-color: rgba(144, 127, 127, 0.666);
}
.pic-box-2{
    z-index: 3;
    background-color: rgba(154, 77, 77, 0.673);
    transform: skew(0deg,10deg);
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.789);
}

.pic-box-3{
    z-index: 3;
    transform: skew(10deg,10deg);
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.789);
    border: 2px solid rgb(92, 12, 12);
    background-image: url(../public/assets/illustrations/pic22.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    

}

.pic-text-info-con{
   background: linear-gradient(20deg,rgb(92,12,12),rgb(50,23,20)) ;
   width: 50%;
   height: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   padding: 0px 1em;
   font-family: 'Anton', sans-serif;
   font-size: 2em;
   color: white;
   border-top-left-radius: 30%;
   border-bottom-right-radius: 10%;
   box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.47);
}

/* Phrase section */

.phrase-main-con{
    grid-column: 1/13;
}
.phrase-one-con{

     height: 600px;
     width: 100%;
     display: flex;
    
}

.phrase-con{

   width: 60%;
   background: var(--bgcolor2);
   clip-path: polygon(0% 0, 100% 0%, 100% 80%, 0 100%);
   display: flex;
   justify-content: center;
   align-items: center;
}
.phrase-con h2{
    font-size: 2vw;
    height: 50%;
    width: 80%;
    background: white;
    transform: skewX(15deg);
    padding: 2em;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.47);
    color: black;
    border-radius: 8px;
    margin: 0px 1em;
    font-family: var(--fontFamily);
}
.phrase-pic-con{

   height: 100%;
   width: 40%;
   display: flex;
   justify-content: center;
   align-items: center;
   background: linear-gradient(20deg,rgb(4, 53, 57),rgb(41, 2, 57),rgb(41, 2, 57));
   clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 80%);
   margin-left: -8px;
  
}
.phrase-con-for-phrase-slider{
    height: auto;
    width: auto;
    margin: 7px;
    
}
.slider-img-con-phrase{

   object-fit: cover;
}

.signup-alert-con{
   width:100%;
   display:flex;
   justify-content:center;
   align-items:center;
   flex-direction:column;
  
  
}
.gen-bg{
   background: var(--bgcolor) !important;
   color: white !important;
}

/* End */

 .mtc-sec-info-con{

    grid-column: 1/13;
    padding-bottom: 2em;
    
 }
 .mtc-inner-sec-info-con{
   width: 100%;
   height: 100%;
   padding-left: 4rem;
   padding-right: 4rem; 
   display: flex;
   justify-content: center;
   align-items: center;  
}
 .Price-text{
    position: absolute;
    top: 7em;
    left: 0;
    color: white;
    background-color: rgb(92, 12, 12);
    padding: 5px;
    z-index: 3;
    font-weight: 700;
 }
 
 .status-text{
   position: absolute;
   top: 7em;
   right: 0;
   color: white;
   background-color: rgb(92, 12, 12);
   padding: 5px;
   z-index: 3;
   font-weight: 700;
 }
 
 .mtc-nav{
   position:  relative !important;
 }
 
 .mtc-navlist-con{
   width: 100%;
   cursor: pointer;
 }
 .mtc-navlist-con ul{
    display: flex;
    padding: 10px;
 }
 .mtc-navlist-con ul li {
    list-style: none;
    margin-left: 1em;
    text-transform: capitalize ;
    font-weight: 700;
    color: rgb(92, 12, 12);
 }

 .dowbtn{
   background-color: red;
   color: white;
   padding: 5px 7px;
   cursor: pointer;
   font-weight: 800;
   border-radius: 6px;
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.446);
 }

 .map-sec-con{
   grid-column: 1/13;
   background-color: rgb(226, 226, 226);
   padding-bottom: 4em;
 }
 .inner-map-sec-con{
     width: 100%;
     height: 100%;
     padding-left: 4rem;
     padding-right: 4rem;
     display: flex;
       
 }
 .inner-contact-con{

   height: 100%;
   width: 40%;
   padding: 1em;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 .map-con{
   width: 60%;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }
 .inner-map-con {
   box-shadow: 0px 3px 10px rgb(0, 10, 53);
 }
 .inner-map-con iframe{
   border-radius: 7px;
 }
 .mtc-footer{
 
    background: linear-gradient(20deg,rgb(8, 3, 90),rgb(14, 54, 13),rgb(38, 21, 54));
   /*  grid-column: 1/13; */
   height: 500px;
   width: 100%;
   position: relative;
 }
.InstructorPreview-supernova-social-con{

    cursor: pointer;
}
.verify-con-input{

     display: flex;
     flex-direction: column;
}
.verify-input{

     padding: 4px 9px;
     margin: 1em 0px;
}

.passwrd-recory-con{

   width: 20em;
   padding: 3px;
}

.passwrd-recory-con input{

    width: 100%;
    height: 3em;
    padding: 7px 0px;
    margin: 4px 0px;
    border-radius: 3px;
}
.h3-text-passwrd-recry{
    left: 50%;
    transform: translate(-50%,50);
}

 
 footer{
     background: linear-gradient(20deg,rgb(8, 3, 90),rgb(14, 54, 13),rgb(38, 21, 54));
     grid-column: 1/13;
     color: white;
     display: flex;
     flex-direction: column;
    
 }
 .FooterNavCard-con{
   border-left: 1px solid rgba(255, 255, 255, 0.747);
 }

 .FooterNav-big-con{
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: flex-start;
 
    
 }
 .FooterNav-Con{
    width: 320px;
    max-width: 350px;
    height: 60%;
    display: flex;
    flex-direction: column;
    color: white !important;
    margin-top: 1em;
    margin-left: 5px;
 
 }

 .link-btn{

   color: white;
   text-decoration: none;

 }

 .footerATdate{
    color: white;
    width: 100%;
    height: 4em;
    text-align: center;
    padding: 6px 0;
    position: absolute;
    bottom: 0.3em;
 
 }

 /* ---End---- */




 /* Registration Section */

 .main-registration-con{
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100%;
   margin-top: 5em;
   margin-bottom: 5em;
   flex-direction: column;
   
 }

.general-width-con{

   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   overflow-y: auto;
  

}
.inner-registration-con{
 /*   height: 100%;
   width: 40vw; */
   padding: 8px;
}

.inner-registration-con,.signUp-con .css-1inq3om-MuiStepLabel-labelContainer{
   display: flex;
}
.inner-registration-con,.signUp-con .css-m5vj9m-MuiStepper-root{
   margin-bottom: 14px;
}
.css-1fdsijx-ValueContainer {
   padding: 10px 0px;
}
.comfirm-alert-con{

   position: fixed;
   z-index: 9999;
   width: 30%;
   height: 40%;
   right: 50%;
   transform: translate(50%,80%);
   background-color: rgba(0, 0, 0, 0);
   display: flex;
   flex-direction: column;
   background: var(--bgcolor);
   color: white;
   border-radius: 7px;
   padding: 2em;
   text-align: center;
   justify-content: center;
   align-items: center;
   
}

.user-pro-file-course-card-con{

    display: grid;
    grid-gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(100px,350px));
    background-color: red;
    height: auto;
    padding: 8px;

}
.payment-table-con{
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100vh;
   cursor: pointer;
}
.tbl-box-con{
   width: 400px;
   height: auto;
   padding: 4px;
   background: var(--bgcolor);
   border-radius: 7px;
   box-shadow: 0px 3px 10px rgb(0, 0, 0,0.498);
}
.tbl-box{
   background-color: white;
   border-bottom: 2px solid white;
   border-top: 2px solid rgba(0, 0, 0, 0.138);
}
.tbl-box{
    height: 100%;
    display: flex;
}
.tbl-box1{

    width: 20%;
    height: 2.5em;
    background: var(--bgcolor);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    color: white;
}
.tbl-box-hd{
   width: 100%;
   height: 2.5em;
   background-color: gray;
   display: flex;
   justify-content: center;
   align-items: center;
   font-weight: 800;
   background: var(--bgcolor);
   color: white;
 
}
.tbl-box-2{

   width: 80%;
   background-color: rgb(241, 239, 239);
   display: flex;
   justify-content: center;
   align-items: center;
}


.tbl-box-2 span{

    padding: 5px;
}
.tbl-box3{

   width: 100%;
   background-color: rgb(241, 239, 239);
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}
.tbl-box-bt{
   width: 100%;
   height: 2.5em;
   background: var(--bgcolor);
   display: flex;
   justify-content: center;
   align-items: center;
   font-weight: 800;
   color: white;
 
}

/* DashBoard Section */

.post_product-con{

   width: 100%;
   height: 100%;

}
.post-product-img-preview-con{
    height: 10em;
    width: 10em;
    margin: 7px 0px;
    border-radius: 4px;
}
.post-product-img-preview-con .post-p-img{

   object-fit: cover;
   height: 100%;
   width: 100%;
   border-radius: 4px;
}

.Des-text-product-con{

      width: 30em;
      height: 25em;
      overflow-x: scroll !important;
      background-color: red;
      white-space: wrap;
      
}

.newpay{
   background: var(--bgcolor);
   cursor: pointer;
}
.newpaycolor{
   color: white;
   font-weight: 800;
}

/* -----End------ */



/* Workshop Section */


.attendee-register-con{

   height: 100%;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;

}
.inner-attendee-register-con{
   height: 100%;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: white;
}
.inner-attendee-register-con-list{
   max-width: 50%;
}
.inner-attendee-register-con-form{
   max-width: 50%;
}


/* Vidoe Section */


.v-info-vi-con{
     display: flex;
     flex-direction: row;
     height: 200px;
     width: 100%;
}
.v-info-vi-con video{
   width: 30%;  
   height: 100%;
}
.v-info-con-inner{
     width: 70%;
     display: flex;
     align-items: center;
    padding-left: 1em;
   
}

.v-info-con-inner-inner{

    display: flex;
    flex-direction: column;
    line-height: 6px;
    height: 100%;
    width: 40%;
    padding: 6px;
    white-space: nowrap;

}


.des-v-con{
   width: 100%;
   height: auto;
}

.ass-sub-com{


   height: 100%;
   width: 50%;
   padding: 6px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;

}

.mst-con-show{

     width: 20em;
     height: 25em;
}


.more-inner-con{

     width: 300px;
     padding: 10px;
}
.cert-card-con{
  
    height: 100%;
    width: 250px;
}
.cert-img-card-con{
   
   height: 100%;
   width: 100%;
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   
   
 
}
.cert-img-card-con::before{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(220, 220, 220, 0.882);
    z-index: 1;
}
.cert-img-card-con img{

      width: 250px;
     
}
.cert-img-card-con button{
   position: absolute;
   top: 50%;
   transform: translate(-50%,-50%);
   left: 50%;
   white-space: nowrap;
   z-index: 2;
   
}


.inner-int{

   width: 100%;
   height: 100px;
   display: flex;
   justify-content: center;
   align-items: center;
   
}
.inner-int-inner{
   width: 50%;
   height: 100px;
   display: flex;
   justify-content: center;
   align-items: center;
 
}

.mask-num-con .css-g8xom6-MuiInputBase-input-MuiInput-input {
  
    padding: 0px 0 0px; 
    box-sizing: inherit; 
 
}

.mask-num-con .css-g8xom6-MuiInputBase-input-MuiInput-input:disabled{
  
   color: black !important;
   background-color: white;
  -webkit-text-fill-color: #000000;

}