
 /* desktop size */
 @media all and (max-width:1321px) {

    .mmt-center-con{
    
        grid-template-rows: 300px auto; 
       
        
      }
    /* Welcome text Section */
   .welcome-text-con{
      font-size: 6vw !important;
      height: 6em;
   }
    /* Tabs section */
    .mtc-sec-tabs-card-con h3{
         font-size: 2vw !important;
    }
    .mtc-sec-tabs-card-con{
        padding-bottom: 0rem;
        padding-left: 0rem;
        padding-right: 0rem;
     }
     .tabs-table-con{
        width: 90%;
     }
   
   /* Inner map section */
   .inner-map-sec-con{
      flex-direction: column;
   }
   
   .map-con{
      width: 100%;
      height: auto;
      padding: 1em;
    }
    .inner-map-con iframe{
       width: 80vw;
    }
    
    .inner-contact-con{
      height: 100%;
      width: 100%;
      justify-content: flex-start;
    }
   
    
   
    /* ---End---- */
   
    /* Accordion section */
    .mtc-inner-sec-info-con{
      padding-left: 2rem;
      padding-right: 2rem; 
   }
   
    /* ----End--- */
   
    /* Phrase Section */
    .phrase-con h2{
        font-size: 4vw;
        height: 50%;
        width: 80%;
        padding: 1em;
        border-radius: 8px;
        margin: 0px 0em;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        
     }  
   .inner-info-pic-con{
      width: 100%;
      padding-left: 0rem;
      padding-right: 0rem;
   
   }
   .sec-pic-card-con{
      width: 100%;
   }
   
   .sec-info-pic-con{
      justify-content: center;
   }
   
   .box--{
       
         height: 20em;
         width: 20em;
   }
   
   
   .pic-text-info-con{
   
      width: 40%;
      height: 70%;
      font-size: 2.2vw;
      
   }
   
   
   .comfirm-alert-con{

     
      width: 70%;
      height: 40%;
   
      
   }

   .main-user-pro-con{

      margin-right: 2em;
      margin-left: 2em;
}
   
   
    }
   /* ---End---- */
   
   /* Media queries */
   
   @media all and (max-width:768px) {
    .mmt-center-con{
    
        grid-template-rows: 200px auto;  
        
      }
      .PreviewCon{
         flex-direction: column;
         height: auto;
      } 
   
      .ProductPreview{
         width: 100%;
         overflow-y: visible;
         
     }
     .InstructorPreview{
       
        width: 100%;
     }
      
     .ProductViewText-CourseDescreption-con,.InstructorPreview-supernova-social-con{
      margin-bottom: 3em;
      width: 100%;
   }
   .list-con-for-prev-course{
       max-width: 100%;
   }
   
   .MainContainer{
   
      flex-direction: column-reverse;
      height: auto;
    
   }
   .TextViewContainer{
      height: 40em;
      width: 100%; 
   }
   
   .VidoeContainer{
      height: auto;
      width: 100%;
   }
   .inner-TextViewHeader-inner-img{
      display: none;
   }
   .videoCon{
      width: 80%;
      height: 50%;
      margin-bottom: 1em;
     
   }
   
   .InnerTogglerHeader2-Con{
      margin: 0 5px;
      white-space: nowrap;
   }
   
   /* Welcome text Section */
   .welcome-text-con{
      font-size: 6vw !important;
      height: 6em;
     
   }
   
   /* Main Picture Section */
   .mtc-section{
      grid-template-rows: auto auto auto auto;
   }
   /* Tabs section */
   .mtc-sec-tabs-card-con{
      padding-bottom: 0rem;
      padding-left: 0rem;
      padding-right: 0rem;
   }
   .tabs-table-con{
      width: 90%;
   }
      .mtc-sec-tabs-card-con h3{
        font-size: 2.4vw !important;
   }
   /* Accordion section */
   .mtc-inner-sec-info-con{
      padding-left: 2rem;
      padding-right: 2rem; 
   }
   
   /* Inner map section */
   .inner-map-sec-con{
      flex-direction: column;
   }
   
   .map-con{
      width: 100%;
      height: auto;
      padding: 1em;
    }
    .inner-map-con iframe{
       width: 80vw;
    }
    
    .inner-contact-con{
   
      height: 100%;
      width: 100%;
      justify-content: flex-start;
    }
   
    /* Phrase Slider Section */
   
    .phrase-one-con{
      flex-direction: column-reverse;
      height: 900px;
    }
   
    .phrase-con{
      width: 100%;
      height: 40%;
      clip-path:none;
      background: linear-gradient(to right bottom, rgb(4, 53, 57),rgb(41, 2, 57),rgb(41, 2, 57)) !important;
   }
   .phrase-pic-con{
      height: 50%;
      width: 100%;
      clip-path: none;
      margin-left: 0px;
   }
   .phrase-con h2{
      font-size: 5vw;
      height: 50%;
      width: 80%;
      padding: 1em;
      border-radius: 8px;
      margin: 0px 0em;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      
   }
   .slider-con-for-phrase-picture{
      max-width: 600px;
   }
   .phrase-con-for-phrase-slider{
      margin: 1em;
      
   }
   .slider-img-con-phrase{
       height: 300px;
   }
   
   /* Picture and Text Section */
   .sec-pic-card-con{
      flex-direction: column-reverse;
      width: 100%;
      height: 100%;
   }
   .inner-info-pic-con{
      padding-left: 0rem;
      padding-right: 0rem;
   }
   .sec-info-pic-con{
      width: 100%;
      height: 30rem;
      justify-content: center;
      align-items: center; 
   } 
   
   .pic-text-info-con{
     
      width: 100%;
      height: 30rem;
      justify-content: center;
      align-items: center;
     
   }
   .pic-text-info-con{
      background: none ;
      font-size: 5vw;
      text-align: center;
      border-top-left-radius: 0px;
      border-bottom-right-radius: 0px;
      box-shadow: none;
   }
   
   .trs{
      display: none;
   }
   .signUp-con{

      margin-top: 16em;
     }



     .comfirm-alert-con{

     
      width: 70%;
      height: 40%; 
   }



   .inner-attendee-register-con{
      flex-direction: column !important;
      height: auto;
      padding-top: 20em;
   }  
   .inner-attendee-register-con-list{
      width: 100% !important;
      height: auto;
   }
   .inner-attendee-register-con-form{
      max-width: 100% !important;
      margin-top: 7rem;
  
   }

/* User pro */

.main-user-pro-con{

       margin-right: 2em;
       margin-left: 2em;
}

.main-userpro-inner-con .MuiGrid-container {

    flex-wrap: nowrap !important;
    overflow-x: hidden;
   
}


.css-1byr0tz{
   overflow-x: hidden;
}

.v-info-vi-con{
flex-direction: column;
height: 600px;
}
.des-v-con{
    margin-top: 2.5em;
}
.vi-con-inner{
width: 100%;
height: 100%;
display: flex;
}
.v-info-vi-con video{
width: 100%;  
height: 100%;
}
.v-info-con-inner{
width: 100%;
height: 100%;
flex-direction: column;
}


.v-info-con-inner-inner{

height: 100%;
width: 100%;

}


.cert-card-con{
  
   height: 100%;
   width: 100%;
}

.cert-img-card-con img{

   width: 300px;
  
}




   }
   
   
   /* Mobile size */
   
   @media all and (max-width:546px) {

   

      .VidoeContainer{
         height: 18rem;
         width: 100%;
         align-items: center;
      }
      .videoCon{
         width: 94%;
         height: 100%;
         margin-bottom: 0em;
         margin-top: 0;
      }
   
      .InnerTogglerHeader2-Con{
         margin: 0 9px;
      }
   
      /* Tabs section */
   .mtc-sec-tabs-card-con{
      padding-bottom: 0rem;
      padding-left: 0rem;
      padding-right: 0rem;
   }
   .tabs-table-con{
      width: 100%;
   }
   .mtc-sec-tabs-card-con h3{
      font-size: 2.8vw !important;
 }
   .tabs-table-con .css-19kzrtu {
      padding: 6px !important;
      
  }
     
    /* Phrase Slider Section */
   
    .phrase-one-con{
    
      height: auto;
     
    }
   
    .phrase-con{
      height: 15em;

     
   }
   .phrase-pic-con{
      height: 25em;
      padding-top: 7px;

   }

   .pic-text-info-con{
     
      width: 100%;
      height: 17rem;
      justify-content: center;
      align-items: center;
     
   }
   .box-- {
      height: 18em;
      width: 18em;
  }
       /* Accordion section */
    .mtc-inner-sec-info-con{
      padding-left: 0rem;
      padding-right: 0rem; 
   }

   /* Map Section */
   .inner-map-sec-con{
      width: 100%;
      height: 100%;
      padding-left: 0rem;
      padding-right: 0rem;
     
        
  }
   .map-con{
      padding: 0.5em;
    }
    .inner-contact-con{

      height: 100%;
      width: 40%;
      padding: 0.8em;
     
    }
    .inner-contact-con .css-dft6lt-MuiStepContent-root{
        white-space: nowrap;
    }
    .inner-contact-con .css-1mz1l2x-MuiStepContent-root{
      white-space: nowrap;
    }

    .inner-map-con iframe{
       width: 90vw;
       height: 20rem;
     
    }
    .InstructorPreview{
  
      width: 100%;
      height: 100%;
      padding: 0 18px;
      display: flex;
      flex-direction: column;
      padding-top: 4em;
   }
    .img-InstructorPreview-con img{
  
      width: 20em;
      
   }

   .check-dialog .css-154lg22-MuiPaper-root-MuiDialog-paper{
      
      margin-left: 10px;
      margin-right: 10px;
   }
   .signup-alert-con{
      top: 0em;
   }
   .inner-signup-alert-con{

      width: 90%;
   }
   .alertbox-con{
      width: 60%;
      top: 2em;
   }
 
 
   .comfirm-alert-con{
      width: 90%;
      height: 40%;
   }

  
   .inner-attendee-register-con{
      flex-direction: column !important;
      height: auto;
      padding-top: 20em;

   }  
   .inner-attendee-register-con-list{
      max-width: 100% !important;
      height: auto;
   }
   .inner-attendee-register-con-form{
      max-width: 100% !important;
      margin-top: 7rem;
     
   }

   .css-1byr0tz{
       overflow-x: hidden;
   }

   .main-user-pro-con{

      margin-right: 0.5em;
      margin-left: 0.5em;
}


.v-info-vi-con{
    flex-direction: column;
    height: 500px;
}
.vi-con-inner{
    width: 100%;
    height: 100%;
    display: flex;
}
.v-info-vi-con video{
   width: 100%;  
   height: 100%;
}
.v-info-con-inner{
   width: 100%;
   height: 100%;
   flex-direction: column;
}


.v-info-con-inner-inner{

  height: 100%;
  width: 100%;
 
}

   }
    